import {
	handleLogin,
	handleGetPitureCode,
	handleGetSms,
	handleSavePublicMatchAuth
} from '@index/api/login';
import common from '@index/utils/common';
//import modifypassword from '@index/components/modifypassword.vue';
// import './index.scss';
import {
	JSEncrypt
} from "jsencrypt";
// import wxlogin from 'vue-wxlogin'; 
export default {
	name: 'Login',
	components: {
		
	},	
	data() {
		return {
			loginCodeButton: {
				name: "获取验证码",
				disabled: false
			},
			matchAuthCodeButton: {
				name: "获取验证码",
				disabled: false
			},
			pitureCodeObj: {},
			currentActiveName: 'first',
			form: {
				phone: '',
				code: '',
				password: '',
				smsId: ''
			},
			matchAuthForm: {
				smsId: "",
				code: "",
				company: "",
				liablePerson: "",
				concatPhone: "",
				loginPhone: "",
			},
			matchDialogVisible: false,
			matchAuthRules: {
				company: [{
					required: true,
					message: '请输入单位全称',
					trigger: 'blur'
				}],
				liablePerson: [{
					required: true,
					message: '请输入责任人',
					trigger: 'blur'
				}],
				concatPhone: [{
					required: true,
					message: '请输入联系方式',
					trigger: 'blur'
				}],
				loginPhone: [{
					required: true,
					message: '请输入登陆账号',
					trigger: 'blur'
				}],
				code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				}],
			},
		}
	},
	watch: {},
	created() {
		var lett = this;
		document.addEventListener('keydown', this.listenEvent, false);
		this.getPitureCode();
	},
	mounted() {
		this.tips();
	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
		document.removeEventListener('keydown', this.listenEvent);
	},
	methods: {
		// 检测是微信浏览器或者不是chrome，就温馨提示
		tips() {
			if (/MicroMessenger/ig.test(navigator.userAgent) || !/chrome/ig.test(navigator.userAgent)) {
				// this.$notify.closeAll();
				// this.$notify({
				// 	title: '温馨提示',
				// 	message: '为了更好的体验系统，推荐使用chrome谷歌浏览器访问',
				// 	position: 'bottom-right',
				// 	duration:0,
				// });
				var img = `<div class="no-bg">
				<div style="font-size:20px;color:red;letter-space:1px;">为了更好的体验系统，推荐使用以下浏览器访问</div>
				<img src="tips.png" alt="" /></div>`
				document.getElementById('app').innerHTML = img
			}
		},
		handleSelectLoginWay(tab) { //选择登陆方式
			if ("first" == tab.name) {
				this.form.smsId = "";
				this.form.code = "";
				return;
			}
			if("third" == tab.name){
				this.wxLogin();
				return;
			}
		},
		wxLogin() {
			console.log(">>>wxLogin>>>>");
			
		},
		clickPitureCode() { //单击图片验证码
			this.form.pitureCode = "";
			this.getPitureCode();
		},
		getPitureCode() { //获取图片验证码
			handleGetPitureCode().then(res => {
				if (res.status == 200) {
					this.pitureCodeObj = {
						pictureId: res.data.id,
						src: res.data.src
					}
					this.form.pictureId = res.data.id;
				}

			});
		},

		pwdLogin() { //密码登陆
			let encrypt = new JSEncrypt();
			var requestData = {
				...this.form
			};

			try { //验证
				common.requiredValidate([{
					value: this.form.phone,
					tips: "登陆账户(联系方式)不能为空!"
				}, {
					value: this.form.password,
					tips: "密码不能为空!"
				}, {
					value: this.form.pitureCode,
					tips: "验证码不能为空!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}
			if (this.form.password.length < 6) {
				this.$message({
					type: 'error',
					message: '密码最少6位!'
				});
				return;
			}
			encrypt.setPublicKey(common.publicKey);
			requestData.password = encrypt.encrypt(this.form.password);
			this.loading = true;
			this.loginRequest(requestData);
		},

		smslogin() { //短信登陆
			try { //验证
				common.requiredValidate([{
					value: this.form.phone,
					tips: "登陆账户(联系方式)不能为空!"
				}, {
					value: this.form.code,
					tips: "验证码不能为空!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}
			var requestData = {
				...this.form
			};
			this.loginRequest(requestData);


		},
		loginRequest(requestData) {
			handleLogin(requestData).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					sessionStorage.setItem("user", JSON.stringify(res.data.user));
					sessionStorage.setItem("auth", JSON.stringify(res.data.oauth2Info));
					sessionStorage.setItem('token', 'bearer ' + res.data.oauth2Info.access_token);
					sessionStorage.setItem('loginUserId', res.data.user.id);
					if (!res.data.user.password) {
						sessionStorage.setItem("userPasswrodNullFlag", "YES"); //用户密码为空
					} else {
						sessionStorage.setItem("userPasswrodNullFlag", "NO"); //用户密码为空
					}

					if ((res.data.user.publicMatchFlag &&
							res.data.user.publicMatchFlag == '2') ||
						res.data.isExistAuthMatchFlag == "1") { //存在授权比赛
						this.$router.push({
							path: '/transitionpage'
						})
					} else {
						this.$router.push({
							path: '/team'
						})
					}

				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			})
		},
		getMathAuthSmsCode() { //发布申请权限获取验证码
			var phone = this.matchAuthForm.loginPhone;
			let thisObj = this;
			this.getSmsCode(phone, function(res) {
				thisObj.matchAuthForm.smsId = res;
				var time = 30;
				thisObj.matchAuthCodeButton = {
					name: time + "s",
					disabled: true
				};

				var timer = setInterval(function() {
					if (--time >= 0) {
						thisObj.matchAuthCodeButton = {
							name: time + "s",
							disabled: true
						}
					} else {
						thisObj.matchAuthCodeButton = {
							name: "获取验证码",
							disabled: false
						};
						clearInterval(timer);
					}
				}, 1000)
			});
		},
		getLoginSmsCode() { //获取登陆验证码
			var phone = this.form.phone;
			let thisObj = this;
			this.getSmsCode(phone, function(res) {
				thisObj.form.smsId = res;
				var time = 30;
				thisObj.loginCodeButton = {
					name: time + "s",
					disabled: true
				};

				var timer = setInterval(function() {
					if (--time >= 0) {
						thisObj.loginCodeButton = {
							name: time + "s",
							disabled: true
						}
					} else {
						thisObj.loginCodeButton = {
							name: "获取验证码",
							disabled: false
						};
						clearInterval(timer);
					}
				}, 1000)
			});
		},
		getSmsCode(phone, callback) { //获取短信验证码
			try { //验证
				common.requiredValidate([{
					value: phone,
					tips: "电话不能为空!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}

			handleGetSms(
				phone
			).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: '发送成功请注意查收!'
					});
					callback(res.data.smsId);
					//   this.form.smsId = res.data.smsId;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}

			});

		},
		submitPublicMatchAuth() {
			this.$refs.matchAuthForm.validate((valid) => {
				if (valid) {
					handleSavePublicMatchAuth(this.matchAuthForm).then(res => {
						if (res.status == 200) {
							this.$message({
								type: 'success',
								message: '提交成功等待管理员审核!'
							});
							this.matchDialogVisible = false;
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					});
				} else {

					return false;
				}
			});

		},
		toSmsLogin() {
			this.currentActiveName = 'second';
		},
		listenEvent() {
			var lett = this;
			var key = window.event.keyCode;
			if (key == 13) {
				if (lett.currentActiveName == 'first') {
					lett.pwdLogin();
				}
				if (lett.currentActiveName == 'second') {
					lett.smslogin();
				}
				if (lett.currentActiveName == 'third') {
					lett.smslogin();
				}


			}
		}

	}
}